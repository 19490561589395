import type { AgreementType } from '@azure/services/billingScope';

import { azure } from '@shared/services/api';

/* eslint-disable camelcase */
export type CreateServicePrincipalRequest = {
  application_id?: string;
  tenant_id?: string;
};

export type CreateServicePrincipalResponse = {
  id: string;
  application_id: string;
  tenant_id: string;
  certificate: string;
};

export enum AccessValidationStatus {
  Unknown = 'Unknown',
  Validated = 'Validated',
  AccessDenied = 'AccessDenied',
}

export type AccessValidationResult = {
  status: AccessValidationStatus;
  message: string;
};

export enum PermissionSet {
  SavingsAnalysis = 'SavingsAnalysis',
  Management = 'Management',
}

export type AccessValidationRequest = {
  agreement_type?: AgreementType;
  billing_account_id?: string;
  billing_profile_id?: string; // MCA only
  storage_account_subscription_id?: string;
  storage_account_resource_group_name?: string;
  storage_account_name?: string;
  permission_set?: PermissionSet;
  management_group_id?: string; // optionally validate against custom management group
};

export type ServicePrincipalInfo = {
  id?: string;
  application_id?: string;
  tenant_id?: string;
  thumbprint?: string;
};

export type AccessValidationResponse = {
  service_principal_authenticated: AccessValidationResult;
  service_principal_info: ServicePrincipalInfo;
  billing_scope_access: AccessValidationResult;
  billing_reader_access: AccessValidationResult;
  reservations_access: AccessValidationResult;
  savings_plan_access: AccessValidationResult;
  storage_account_access: AccessValidationResult;
  cost_management_exports_namespace_registered: AccessValidationResult;
};
/* eslint-enable camelcase */

export async function create(request: CreateServicePrincipalRequest) {
  const res = await azure.post<CreateServicePrincipalResponse>(`/v1/service_principals`, request);
  return res.data;
}

export async function get(servicePrincipalId: string) {
  const res = await azure.get<CreateServicePrincipalResponse>(`/v1/service_principals/${servicePrincipalId}`);
  return res.data;
}

export async function validateAccess(servicePrincipalId: string, request: AccessValidationRequest) {
  const res = await azure.post<AccessValidationResponse>(
    `/v1/service_principals/${servicePrincipalId}/validate_access`,
    request
  );
  return res.data;
}

export async function validateTenantAccess(servicePrincipalId: string, tenantId: string, request: AccessValidationRequest) {
  const res = await azure.post<AccessValidationResult>(
    `/v1/service_principals/${servicePrincipalId}/validate_access/${tenantId}`,
    request
  );
  return res.data;
}
